@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Lato", "Prompt", "Noto Sans JP", sans-serif;
  font-size: 16px !important;
  scroll-behavior: smooth !important;
}

body .wrapper {
  background-image: url("/static/images/bg-path.png");
  background-position: center center;
  background-size: auto;
  background-repeat: repeat;
  background-attachment: scroll;
}

.th {
  font-family: "Prompt", sans-serif;
}

.en {
  font-family: "Kanit", sans-serif;
}

.jp {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 500;
}

a {
  text-decoration: none !important;
  cursor: pointer;
}

.img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}

header {
  background-color: #fff;
}

.toolbar {
  padding: 0 24px !important;
}

.bg-b {
  background-color: #000;
}

.sns p {
  font-family: "Yantramanav", sans-serif;
  color: #fff;
  font-size: 12px;
  margin-bottom: 0;
  letter-spacing: 3px;
}

.icon-white svg {
  color: rgb(136, 136, 136);
  font-size: 17px;
}

.icon-white:hover svg {
  color: #fff;
}

.btn-ticket {
  background-color: rgb(204, 14, 100);
}

.btn-ticket:hover {
  background-color: #ec4d4d;
}

.btn-ticket a {
  color: #fff;
  font-size: 14px;
  letter-spacing: 1px;
}

.menu ul {
  display: flex;
  list-style: none;
  gap: 50px;
  margin-bottom: 0;
}

.menu ul li .nav_link {
  color: #000;
}

.menu .nav_link {
  position: relative;
  line-height: 1;
  transition: all 0.2s ease-in;
  padding-bottom: 20px;
}

.menu ul li .nav_link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #a40000;
  transition: width 0.2s ease;
}

.menu ul li .nav_link:hover {
  color: #a40000;
}

.menu ul li .nav_link:hover:after {
  width: 50%;
}

.lang-button a {
  font-size: 14px !important;
  color: #000;
  padding: 5px 10px;
  font-weight: 700;
  font-family: "Prompt", sans-serif;
}

.lang-button a:hover,
.lang-button a.active {
  background-color: #a40000 !important;
  color: #ffff !important;
  padding: 5px 10px;
  border-radius: 2px;
}

.lang-button hr {
  border-color: #001736 !important;
  opacity: 1 !important;
  align-self: center !important;
  height: 20px !important;
}

.c-bg-movie {
  min-height: 100vh;
  position: relative;
}

.c-bg-movie__upper-contents {
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.c-bg-movie__overlay {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background-color: rgb(0, 0, 0);
}

.c-bg-movie__movie {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: center center no-repeat;
  background-size: cover;
  background: url("/static/images/video_banner.png");
}

.c-bg-movie__movie iframe {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.wrap {
  position: relative;
  height: 100vh;
}

.video-bg {
  position: relative;
  height: 100%;
  background: #000;
  overflow: hidden;
}

.video-bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23000000' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}

.video-bg iframe {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 60vw;
}

.content-banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
}

.banner-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
}

.banner-inner p {
  font-size: 24px;
  margin-bottom: 30px;
  text-shadow: 2px 2px 4px #000;
}

.banner-inner h1 {
  font-weight: 700;
  font-size: 48px;
  text-shadow: 2px 2px 4px #000;
}

.banner-inner h2 {
  font-size: 30px;
  text-shadow: 2px 2px 4px #000;
}

.section {
  padding: 80px 0;
}

.heading_section {
  margin-bottom: 30px;
}

.heading_inner {
  position: relative;
  display: flex;
  flex-direction: column;
}

.heading-subtitle {
  align-items: center;
  text-align: start;
  justify-content: start;
  justify-items: start;
  display: flex;
  margin-bottom: 15px;
  font-size: 18px;
  color: #a40000;
  font-weight: 600;
}

.heading-deco-inner {
  margin-right: 20px;
  position: relative;
  align-items: center;
  display: inline-flex;
}

.heading-deco-inner::before {
  content: "";
  position: relative;
  width: clamp(1.25rem, 0.9164rem + 1.8405vw, 3.125rem);
  height: 2px;
  background-color: rgba(164, 0, 0, 0.6);
  display: inline-flex;
  top: 0;
  left: 0;
  right: auto;
  bottom: 0;
  margin-right: 15px;
}

.content-icon-wrapper svg {
  font-size: 24px;
  color: #a40000;
}

.heading-title_m {
  font-weight: bold;
  text-transform: uppercase;
}

.heading-title_m svg {
  font-size: 16px;
  vertical-align: baseline;
  color: #a40000;
}

.heading-title_m-center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.heading-title h4 {
  font-size: 18px;
  color: #a40000;
}

.box_text-black {
  position: relative;
}

.text-content_highlight {
  background-color: #000;
  padding: 40px;
  border-radius: 10px;
  position: relative;
}

.text-content_highlight p {
  color: #fff;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.8;
}

.icon-position_right {
  position: absolute;
  right: 20px;
  top: -25%;
}

.icon-position_right svg {
  color: rgb(221, 66, 66);
  font-size: 70px;
}

.content-text p {
  font-size: 16px;
  font-family: "Prompt";
}

.gray-b {
  background-color: #f7f7f7;
  position: relative;
}

.black-b {
  background-color: #000;
  position: relative;
  color: #fff;
}

.icon_background-text-holder-one {
  display: flex;
  position: absolute;
  width: 100%;
  z-index: 0;
  justify-content: flex-start;
  top: 0%;
  font-size: 350px;
  left: 0;
}

.icon_background-text-holder {
  display: flex;
  position: absolute;
  width: 100%;
  z-index: 0;
  justify-content: flex-end;
  bottom: 0%;
  font-size: 350px;
  right: 30px;
}

.icon_background-text {
  font-family: "Kanit", sans-serif;
  font-weight: 900;
  font-style: italic;
  font-size: 100%;
  line-height: 1.1em;
  letter-spacing: -0.025em;
  text-transform: uppercase;
  color: #ebebeb;
  display: flex;
}

.icon_character {
  transform-style: preserve-3d;
  opacity: 1;
  transform: rotateX(0) rotateY(0);
  transform-origin: 50% 40% 0;
  transition: 0.53s cubic-bezier(0.45, 0.05, 0.37, 0.93);
  z-index: -1;
  font-weight: 700;
}

.match-inner {
  position: relative;
  z-index: 1;
}

.map_detail-inner {
  border-bottom: 1px solid #e8e8e9;
  padding: 20px;
  font-size: 16px;
}

.map_detail-inner:last-child {
  border: none;
}

.sns-inner h5 {
  color: #fff;
  line-height: 1.5;
}

.icon-stack {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  display: inline-block;
  cursor: pointer;
  width: 80px;
  height: 80px;
  background-color: #f5f5f5;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.12), 0 -1px 8px rgba(0, 0, 0, 0.08);
  margin: 0px 12px;
  transition: 0.7s;
  border-radius: 10px;
}

.icon-fb svg {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  fill: #3b5998;
  transition: 0.7s;
}

.icon-instagram svg {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.7s;
}

.icon:hover {
  transition: 0.7s;
  background-color: #3b5998;
  animation: shadow-pop-tr 0.4s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}

.icon:hover svg {
  fill: #fff;
  transition: 0.7s;
}

.icon-instagram svg {
  fill: #cc2366;
  width: 30px;
}

.icon-instagram:hover {
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
}

.icon-instagram:hover svg {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #fff;
}

.dec-inner-red {
  background-color: #a40000;
  padding: 15px 20px;
  color: #fff;
  border-radius: 10px;
  margin: 25px 0;
}

.dec-inner-red p {
  margin-bottom: 5px;
}

.dec-inner-gray {
  background-color: #f0f0f0;
  padding: 15px 20px;
  color: #000;
  border-radius: 10px;
  margin: 25px 0;
}

.dec-inner-gray hr {
  border-color: rgb(148, 148, 148);
  margin: 25px auto;
}

.text-heading_section {
  color: #fff;
  margin-bottom: 0;
}

.red-b {
  background-color: #a40000;
  padding: 80px 0;
  color: #fff;
  background: url("/static/images/dot.png") #a40000;
}

.text-shadow {
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
}

.darkgary-b {
  background-color: #b30b0b;
  color: #fff;
}

.gallery div {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding: 10px;
  justify-content: flex-start;
  gap: 5px;
}

.gallery-inner {
  width: calc((100% - 20px) / 5);
}

.footer {
  padding: 50px 0;
}

.footer h6 {
  line-height: 1.5;
  font-size: 16px;
}

.footer a {
  color: #a40000;
  font-size: 16px;
}

.footer a:hover {
  color: #dc2743;
}

.totop {
  box-shadow: 0px 4px 20px rgb(170 180 190 / 30%) !important;
  background-color: #c2e0ff !important;
}

.totop:hover {
  background-color: #99ccf3 !important;
}

.admin-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.admin-header-inner {
  width: 100%;
}

.mb_menu {
  background-color: #000 !important;
  border-radius: 5px !important;
  margin-right: 0 !important;
}

.mb_menu svg {
  fill: #fff;
}

.drawer a li {
  color: #fff;
  border-bottom: 1px solid #333;
  font-size: 16px;
  padding: 15px 16px;
}

.drawer a:last-child li {
  border: none;
}

@media screen and (max-width: 1366px) {
  .menu ul {
    gap: 30px;
  }
  .text-content_highlight {
    padding: 30px;
  }
  .icon_background-text-holder,
  .icon_background-text-holder-one {
    font-size: 200px;
  }

  .sns-inner h5 {
    font-size: 16px;
  }
}

@media screen and (max-width: 1180px) {
  .menu .nav_link {
    font-size: 14px;
  }
  .menu ul {
    gap: 15px;
  }
}

@media screen and (max-width: 820px) {
  .logo {
    width: 80px;
    height: auto;
  }
}

@media screen and (max-width: 767px) {
  .icon_background-text-holder,
  .icon_background-text-holder-one {
    display: none;
  }
  .dec-inner-gray {
    word-wrap: break-word;
  }
  .gallery-inner {
    width: calc((100% - 20px) / 3);
  }
  .banner-inner {
    justify-content: center;
    text-align: center;
  }
  .banner-inner p {
    font-size: 16px;
  }
  .banner-inner h1 {
    font-size: 24px;
  }
  .banner-inner h2 {
    font-size: 20px;
  }
  .video-bg,
  .wrap {
    height: 40vh;
    margin-top: 80px;
  }
  .toolbar {
    padding: 0 15px !important;
  }
  .icon-position_right {
    top: -10%;
  }
  .footer h6 {
    font-size: 14px;
    line-height: 1.5;
  }
  .footer p {
    font-size: 12px;
  }
  .footer {
    padding: 30px 0;
  }
  .video-a h5 {
    margin: 20px 0;
    font-size: 16px;
  }
  .heading-title_m-center {
    font-size: 30px;
  }
  .text-heading_section {
    font-size: 18px;
  }
  .section {
    padding: 50px 0;
  }
}

.carousel {
  position: relative;
}
.embla {
  --slide-spacing: 1rem;
  --slide-size: 100%;
  --slide-height: 500px;
  padding: 0;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  display: flex;
  flex-direction: row;
  height: auto;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
}
.embla__slide__img {
  display: block;
  height: var(--slide-height) !important;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.embla__slide__text {
  width: 20%;
  height: auto;
  z-index: 1;
  position: absolute;
  bottom: 30%;
  left: 5%;
  border-radius: 50%;
  background-color: rgba(var(--background-site-rgb-value), 0.85);
  line-height: 1.5;
  font-weight: 900;
  text-align: left;
}
.embla__slide__text-jp{
  bottom: 20%;
}
.embla__slide__text > span {
  color: var(--brand-primary);
  background-image: linear-gradient(
    45deg,
    var(--brand-primary),
    var(--brand-secondary)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.6rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.embla__dot,
.embla__button {
  -webkit-appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
}
.embla__dots {
  z-index: 1;
  bottom: 1.2rem;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.embla__dot {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
.embla__dot:after {
  background-color: rgba(000, 000, 000, 0.5);
  border-radius: 0.2rem;
  width: 100%;
  height: 0.3rem;
  content: "";
}
.embla__dot--selected:after {
  background: #0062c0;
}
.embla__button {
  z-index: 1;
  color: #fff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  background: rgba(0, 100, 194, 0.5);
}
.embla__button--prev {
  left: 1.4rem;
}
.embla__button--next {
  right: 1.4rem;
}
.embla__button:disabled {
  opacity: 0.3;
}
.embla__button__svg {
  width: 60%;
  height: 60%;
  filter: invert(100%) sepia(0%) saturate(7492%) hue-rotate(168deg)
    brightness(121%) contrast(94%);
}

.embla__dots,
.embla__button {
  display: none;
}

@media screen and (max-width: 1600px) {
  .embla__slide__text {
    bottom: 25%;
    width: 23%;
  }
  .embla__slide__text-jp {
    bottom: 20%;
    width: 25%;
  }

  .embla__slide__text-jp p{
    font-size: 14px !important;
  }
}



@media screen and (max-width: 1180px) {
  .embla__slide__text {
    bottom: 0;
    width: 50%;
    right: 0;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .text-banner_inner button {
    margin-top: 0;
    background-color: #0062c0 !important;
    color: #fff !important;
  }
  .text-banner_inner h1{
    font-size: 30px;
    line-height: 1.4;
  }
}

@media screen and (max-width: 767px) {
  .embla__slide__text{
    width: 100%;
    padding: 50px 30px;
  }
}
